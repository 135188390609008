
window.OFModels = window.OFModels || {};

OFModels.Submissions = {
  conversionButtonAllowsOneClick: function() {
    $('.btn.convert-to-product').confirmedClick(function() {
      var $el = $(this);
      $el.prop('disabeld', true).attr('disabled', 'disabled').addClass('disabled');
    });
  }
};

$(document).on('turbolinks:load', function() {
  OFModels.Submissions.conversionButtonAllowsOneClick();
});
