/**
 * Radio button. Fits with the partial 'shared/radio_button'
 */

import { Controller } from 'stimulus'

export default class extends Controller {
  change(event) {
    const buttons = this.element.querySelectorAll('label')
    buttons.forEach(button => button.classList.remove('active'))
    event.target.parentNode.classList.add('active')
  }
}
