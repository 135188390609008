import React from 'react';
import ReactDOM from 'react-dom'
import Loader from './Loader.jsx.erb';
import autosize from 'autosize';

export default class RecognizerLink extends React.Component {

  constructor(props)  {
    super(props);
    this.getImageText = this.getImageText.bind(this);
    this.state = { recognizing: false };
  }

  getImageText(e) {
    e.preventDefault();
    this.setState({
      recognizing: true
    });
    var url = this.props.url;
    var method = 'POST';
    $.ajax({
      url: url,
      dataType: 'json',
      type: method,
      success: function(data) {
        var node = $(ReactDOM.findDOMNode(this));
        var text_field = node.parent().siblings('textarea');
        text_field.val(data);
        autosize.update(text_field);
        this.setState({
          recognizing: false
        });
      }.bind(this),
      error: function(xhr, status, err) {
        this.setState({
          errors: xhr.responseJSON,
          recognizing: false
        });
      }
    });
  }

  render() {
    return(
      <button onClick={ this.getImageText } disabled={ this.state.recognizing } className="btn btn-sm btn-secondary float-right" style= { { marginTop: '-45px' } }>
        <React.Fragment>{I18n.js.get_text}</React.Fragment>
        <Loader show={this.state.recognizing} />
      </button>
    )
  }
}
