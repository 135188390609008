document.addEventListener("turbolinks:load", () => {
  window.show_div = function(elem, idField, idField2, idField3) {
    var radioValue = $(elem).val();
    var taskName = $(elem).data('controls-section');
    if(radioValue === 'yes') {
      document.getElementById(idField).style.display = '';
      document.getElementById(idField2).style.display = 'none';
      document.getElementById(idField3).style.display = 'none';
      reset_radio_form('issue-list');
      reset_radio_params(taskName + '-question', radioValue);
      reset_text_form('quantity-number');
      reset_text_form('task_answer_issues_' + taskName + '_remarks');
    } else {
      document.getElementById(idField2).style.display = '';
      document.getElementById(idField).style.display = 'none';
      removeActiveClass('#unit');
      removeActiveClass('#package');
      reset_radio_params(taskName + '-question', radioValue);
    }
  };

  window.show_issue_form = function(elem, radioValue, idField) {
    if($(elem).val() === radioValue) {
      document.getElementById(idField).style.display = '';
    } else {
      document.getElementById(idField).style.display = 'none';
    }
  };

  window.removeActiveClass = function(elem) {
    if ($('.community-task-answer form.form-task-answer').find(elem + ' label').hasClass('active')) {
      document.querySelector(elem + ' .active').classList.remove('active')
    }
  };

  window.reset_radio_form = function(idField) {
    var checkedRadio = document.getElementById(idField).querySelectorAll('input:checked');
    if (checkedRadio.length > 0) {
      document.getElementById(idField).querySelectorAll('input:checked')[0].checked = false;
    }
  };

  window.reset_radio_params = function(idField, radioValue) {
    var element = document.getElementById(idField);
    if (element != null) {
      var checkedRadio = element.querySelectorAll('input:checked');
      if (checkedRadio.length > 0) {
        if (document.getElementById(idField).querySelectorAll('input:checked')[0].value !== radioValue) {
          document.getElementById(idField).querySelectorAll('input:checked')[0].checked = false;
        }
      }
    }
  };

  window.reset_text_form = function(idField) {
    var element = document.getElementById(idField);
    if (element != null) {
      element.value = '';
    }
  }
});
