import React from 'react';
import ReactDOM from 'react-dom'

export default class FlashMessages extends React.Component {
  _isMounted = false;

  constructor(props)  {
    super(props);
    this.state = {
      messages: this.props.data
    };
  }

  static get defaultProps() {
    return {
      messages: {}
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    ReactDOM.render(
      React.createElement(FlashMessages, null), document.getElementById('flash-messages')
    );
  }

  componentDidMount() {
    this._isMounted = true;
    return $(document).ajaxComplete(((event, xhr, settings) => {
      let header_messages, messages;
      header_messages = decodeURI(xhr.getResponseHeader('X-FlashMessages'));
      messages = {};
      if (header_messages) {
        $('#flash-messages').empty();
        messages = JSON.parse(header_messages);
      }
      if (this._isMounted) {
        return this.setState({
          messages: messages
        });
      }
    }).bind(this));
  }

  static messageClass(messageType) {
    if (messageType === 'notice') {
      return 'alert alert-success';
    }
    if (messageType === 'success') {
      return 'alert alert-success';
    }
    if (messageType === 'info') {
      return 'alert alert-info';
    }
    if (messageType === 'warning') {
      return 'alert alert-warning';
    }
    if (messageType === 'alert') {
      return 'alert alert-danger';
    }
    if (messageType === 'error') {
      return 'alert alert-danger';
    }
    alert(messageType)
  }

  render() {
    let messageType;
    let results = [];
    for (messageType in this.state.messages) {
      results.push(
        <div key={messageType} className={ FlashMessages.messageClass(messageType) }>
          <div className='container'>
            <button className='close' data-dismiss='alert'> &times; </button>
            { this.state.messages[messageType] }
          </div>
        </div>
      );
    }
    return results;
  }
}
