import Alpine from 'alpinejs';
window.Alpine ||= Alpine;

function handleKeydown(key) {
  if (key.code === 'Escape') {
    this.closeFullScreen()
  }
}

function nextIn(idx, range) {
  if (idx === range[1]) {
    return range[0]
  } else {
    return idx + 1
  }
}

function previousIn(idx, range) {
  if (idx === range[0]) {
    return range[1]
  } else {
    return idx - 1
  }
}

/**
 * Main Gallery component
 */
Alpine.Gallery = args => {
  /**
   * accepted args
   *   - grid_display, boolean
   */

  return {
    currentIndex: 0,
    fullscreen: false,
    fullscreenImageSrc: '',
    grid_display: 'grid_display' in args ? args.grid_display : false,
    items: [],

    init() {
      this.items = this.$el.querySelectorAll('[data-gallery-item]')

      if (this.currentIndex < 0 || this.currentIndex > this.items.length -1) {
        this.currentIndex = 0
      }

      if (!this.grid_display) {
        this.displayItem(this.currentIndex)
      }
    },

    next() {
      this.currentIndex = nextIn(this.currentIndex, [0, this.items.length - 1])
      this.displayItem(this.currentIndex)
    },

    previous() {
      this.currentIndex = previousIn(this.currentIndex, [0, this.items.length - 1])
      this.displayItem(this.currentIndex)
    },

    displayItem(index) {
      this.items.forEach((node, idx) => {
        if (idx === index) {
          node.classList.remove('d-none')
        } else {
          node.classList.add('d-none')
        }
      })
    },

    openFullScreen(imageSrc) {
      this.fullscreen = true
      this.fullscreenImageSrc = imageSrc

      // this "hack" is used to keep the eventHandler reference,
      // used later to remove the event listener
      this.eventHandler = handleKeydown.bind(this)

      document.addEventListener('keydown', this.eventHandler)
    },

    closeFullScreen() {
      this.fullscreen = false
      this.fullscreenImageSrc = ''
      document.removeEventListener('keydown', this.eventHandler)
    },

    removeImage(imageId) {
      this.$el.querySelector(`[data-gallery-item="${imageId}"]`).remove()
      this.init()
    },

    duplicateImage() {
      // At the moment it's easier to just reload the page,
      // instead of changing all the attributes of the new image
      window.location.reload()
    },
  }
}
