import React from 'react';

export default class Pager extends React.Component {

  constructor(props)  {
    super(props);
    this.handlePreviousClick = this.handlePreviousClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handlePreviousClick(event) {
    event.preventDefault();
    this.props.handlePrevious();
  }

  handleNextClick(event) {
    event.preventDefault();
    this.props.handleNext();
  }

  render(){

    var previousLink = (this.props.page === 0 ? "" : <li className="page-item">
                                                       <a href="#" onClick={ this.handlePreviousClick }>
                                                         <span className="page-link">
                                                           <span aria-hidden="true">
                                                             <React.Fragment>{I18n.js.previous}</React.Fragment>
                                                           </span>
                                                         </span>
                                                       </a>
                                                    </li> );
    var nextLink = (this.props.page + 1 === this.props.nbPages ? "" : <li className="page-item">
                                                                        <a href="#" onClick={ this.handleNextClick }>
                                                                          <span className="page-link">
                                                                            <span aria-hidden="true">
                                                                              <React.Fragment>{I18n.js.next}</React.Fragment>
                                                                            </span>
                                                                          </span>
                                                                        </a>
                                                                      </li>);
    return (
      <div className="d-flex">
        <nav className="mx-auto" aria-label="pagination">
          <ul className="pagination">
            { previousLink }
            { nextLink }
          </ul>
        </nav>
      </div>
    )
  }
}
