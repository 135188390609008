// Inspiration from
// https://gomakethings.com/converting-a-string-into-markup-with-vanilla-js/

export default {
  stringToHTML(str) {
    // If DOMParser is supported, use it
    if (this.DOMParserSupported()) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(str, 'text/html');
      return doc.body;
    }

    // Otherwise, fallback to old-school method
    const dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  },

  DOMParserSupported() {
    if (!window.DOMParser) return false;
    const parser = new DOMParser();
    try {
      parser.parseFromString('x', 'text/html');
    } catch (err) {
      return false;
    }
    return true;
  }
}
