import TomSelect from 'tom-select/dist/js/tom-select.base.min'
import Alpine from 'alpinejs';

Alpine.FormNutrient = args => {
  return {
    portion_quantity: '',
    portion_unit: '',
    displayed_nutrients: [],

    init() {
      this.portion_quantity = args.portion_quantity
      this.portion_unit = args.portion_unit
      this.displayed_nutrients = args.displayed_nutrients.map(i => parseInt(i))

      const select = new TomSelect(this.$refs.select)

      select.on("item_add", value => {
        const nutrientId = parseInt(value)
        if (this.displayed_nutrients.includes(nutrientId)) {
          return
        }

        this.displayed_nutrients.push(nutrientId)

        select.clear()
      })
    },

    removeNutrient(nutrientId) {
      this.displayed_nutrients = this.displayed_nutrients.filter(id => {
        return id !== nutrientId
      })

      this.$refs[`field-${nutrientId}`].value = ""
    },
  }
}
