import React from 'react';
import moment from 'moment';

export default class Product extends React.Component {

  static productUrl(objectId) {
    return '/' + EnvVars.FoodRepoCountry + '/products/' + objectId;
  }

  render() {
    var product = this.props.product;
    var image = product.image_url ? <img alt='Product image' className="img-rounded" src={ product.image_url } /> : '';
    var productNameTranslated = product['display_name_' + EnvVars.i18nLocale];
    var productName = { __html: productNameTranslated };
    return (
      <div className="list-group-item">
        <a href="#">{ image }</a>
        <a className="sans-underline" href={ Product.productUrl(this.props.product.objectID) }>
          <div>
            <span className='product-name' dangerouslySetInnerHTML={ productName } /><br/>
            <span className="text-light">{ moment(product.created_at).format('YYYY-MM-DD') }</span>
          </div>
        </a>
      </div>
    )
  }
}
