import axios from 'axios'
import srcset from 'srcset'
import Alpine from 'alpinejs';

/**
 * Handles interactions for a single image
 */
Alpine.GalleryItem = args => {
  return {
    fullscreenImageSrc: args.fullscreenImageSrc,
    imageId: null,
    imageSrc: '',
    imageSrcset: '',
    toolbarDisabled: false,

    init() {
      this.imageId = this.$el.getAttribute('data-gallery-item')
      this.imageSrc = this.$refs.image.getAttribute('src')
      this.imageSrcset = this.$refs.image.getAttribute('srcset')
    },

    fireEvent(name, args) {
      this.$el.dispatchEvent(new CustomEvent(name, {
        bubbles: true,
        detail: args
      }))
    },

    getCsrfToken() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },

    openFullScreen() {
      this.fireEvent('open-fullscreen', {
        imageId: this.fullscreenImageSrc
      })
    },

    reloadImage(pathOrUrl) {
      const url = new URL(pathOrUrl, window.location.origin)
      url.search = `?v=${Date.now().toString()}`
      return url.toString()
    },

    reloadImageSrcset(imageSrcset) {
      if (imageSrcset === null) {
        return imageSrcset
      }

      const newImageSrcset = srcset.parse(imageSrcset).map(srcsetItem => {
        srcsetItem.url = this.reloadImage(srcsetItem.url)
        return srcsetItem
      })

      return srcset.stringify(newImageSrcset)
    },

    rotate(queryPath, angle) {
      this.toolbarDisabled = true

      axios({
        url: queryPath,
        method: 'patch',
        headers: {
          'Accept': 'application/json',
        },
        data: {
          image: {
            crop_r: angle
          }
        },
      }).then(() => {
        this.fullscreenImageSrc = this.reloadImage(this.fullscreenImageSrc)
        this.imageSrc = this.reloadImage(this.imageSrc)
        this.imageSrcset = this.reloadImageSrcset(this.imageSrcset)
      }).catch(error => {
        console.warn('rotation error =>', error)
      }).then(() => {
        this.toolbarDisabled = false
      })
    },

    rotateLeft(queryPath) {
      this.rotate(queryPath, -90)
    },

    rotateRight(queryPath) {
      this.rotate(queryPath, 90)
    },

    duplicate(queryPath) {
      this.toolbarDisabled = true

      axios({
        url: queryPath,
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': this.getCsrfToken()
        },
      }).then(() => {
        this.fireEvent('image-duplicated', { imageId: this.imageId })
      }).catch(error => {
        console.warn('duplication error =>', error)
      }).then(() => {
        this.toolbarDisabled = false
      })
    },

    edit(queryPath) {
      window.location = queryPath
    },

    destroy_image(queryPath) {
      if (confirm('Are you sure to remove this image?') === false) {
        return
      }

      this.toolbarDisabled = true

      axios({
        url: queryPath,
        method: 'delete',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': this.getCsrfToken()
        },
      }).then(() => {
        this.fireEvent('image-removed', { imageId: this.imageId })
        this.$el.remove()
      }).catch(error => {
        console.warn('destroy error =>', error)
      }).then(() => {
        this.toolbarDisabled = false
      })
    },

    updateCategory(categoryId, target) {
      if (target.checked) {
        axios({
          url: target.getAttribute('data-add-category-path'),
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': this.getCsrfToken()
          },
          data: {
            categorisation: {
              category_id: categoryId,
            }
          },
        }).catch(error => {
          console.warn('category update error =>', error)
        })
      } else {
        axios({
          url: target.getAttribute('data-remove-category-path'),
          method: 'delete',
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': this.getCsrfToken()
          },
        }).catch(error => {
          console.warn('category update error =>', error)
        })
      }
    }
  }
}
