import React from 'react';
import Image from './Image';
import CropBox from './CropBox.jsx.erb';

export default class Cropper extends React.Component {

  constructor(props)  {
    super(props);
    this.setActiveState = this.setActiveState.bind(this);
    this.state = { active: false };
  }

  setActiveState() {
    this.setState({
      active: !this.state.active
    })
  }

  render() {
    var imageElement = (this.state.active ?
      <CropBox src={ this.props.imageSrc } imageUrl={ this.props.imageUrl } onButtonClick={ this.setActiveState } /> :
      <Image src={ this.props.imageSrc } imageUrl={ this.props.imageUrl } onButtonClick={ this.setActiveState } />);
    return(
      <div>
        {imageElement}
      </div>
    )
  }
}
