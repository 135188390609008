$(document).on('turbolinks:load', function() {
  //hide or show the "back to top button" depending on scroll from top
  $(window).scroll(function(){
    if ($(this).scrollTop() > 900 ) {
      $('.back-to-top-button').removeClass('d-none');
    } else {
      $('.back-to-top-button').addClass('d-none');
    }
  });

  //smooth scroll to top
  $('.back-to-top-button').on('click', function(event){
    event.preventDefault();
    $('body,html').animate({
        scrollTop: 0
      }
    );
  });
});
