import React from 'react';

export default class IssueForm extends React.Component {

  componentDidMount(){
    grecaptcha.render('recaptcha', {
      sitekey: EnvVars.recaptchaPublicKey,
    });
  }

  render() {
    var baseErrors = [];
    if (this.props.errors.base) {
      $.each(this.props.errors.base, function(i, error) {
        baseErrors.push(<div key={ i } className="alert alert-danger" role="alert">{ error }</div>)
      })
    }

    var textErrors = [];
    var textFormGroupClass = ['form-group'];
    if (this.props.errors.text) {
      textFormGroupClass.push('has-error');
      $.each(this.props.errors.text, function(i, error) {
        textErrors.push(<span key={ i } className="help-block">Text {error}</span>)
      })
    }

    var recaptchaErrors = [];
    var recaptchaFormGroupClass = ['form-group'];
    if (this.props.errors.recaptcha) {
      recaptchaFormGroupClass.push('has-error');
      $.each(this.props.errors.recaptcha, function(i, error) {
        recaptchaErrors.push(<span key={ i } className="help-block">{error}</span>)
      })
    }

    return (
      <div>
        { baseErrors }
        <form className='form' action={ this.props.issueUrl } onSubmit={ this.props.submitForm }>
          <div className={textFormGroupClass.join(' ')}>
            <textarea
              className="form-control"
              name="issue[text]"
              id="issue_text"
              disabled={ this.props.loadingFlag }
              ref={(textArea) => this.textArea = textArea}
              placeholder={ this.props.placeholderText }
            />
            { textErrors }
          </div>
          <div className={recaptchaFormGroupClass.join(' ')}>
            <div id='recaptcha' />
            { recaptchaErrors }
          </div>
          <div className="form-actions">
            <input
              type="submit"
              value={ this.props.submitText }
              className="btn btn-sm btn-secondary"
              disabled={ this.props.loadingFlag } />
          </div>
        </form>
      </div>
    )
  }
}
