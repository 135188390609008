import React from 'react';

export default class Loader extends React.Component {

  constructor(props)  {
    super(props);
    this.state = { show: this.props.show };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
    })
  }

  render() {
    var display = this.props.className+" "+(this.state.show ? 'loader show' : 'loader d-none');
    return(
      <div className={ display }>
        <img alt='Loader' src='https://d3q151qceqpiv0.cloudfront.net/assets/loading-7596d30b67b1d0c2cf6fb5b09aa16d7c1a61472b6fcb0e3a5533a7a0e7816950.gif' />
      </div>
    )
  }
}
