window.Utility = window.Utility || {};

Utility.ConfirmedClick = {
  add: function() {
    $.fn.confirmedClick = function(callback) {
      this.each(function() {
        let $el = $(this)
        if ($el.data('confirm')) {
          $el.on('confirm:complete', function(evt,res){
            if (res) {
              callback.call(this);
            }
          });
        } else {
          $el.click(function(){
            callback.call(this);
          });
        }
      });
      return this;
    }
  }
}

$(document).on('turbolinks:load', function() {
  Utility.ConfirmedClick.add();
});
