import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import de from 'javascript-time-ago/locale/de'
import it from 'javascript-time-ago/locale/it'

JavascriptTimeAgo.locale(en);
JavascriptTimeAgo.locale(fr);
JavascriptTimeAgo.locale(de);
JavascriptTimeAgo.locale(it);

export default class TimeAgo extends React.Component {
  render() {
    return (
      <div>
        { this.props.beforeText === true ? I18n.js.product_added : '' }
        <ReactTimeAgo date={new Date(this.props.date)} locale={this.props.locale} />
      </div>
    )
  }
}
