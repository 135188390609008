import React from 'react';
import Alert from './Alert';

export default class Flashes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: props.messages };
  }

  render() {
    return (
      <div>
        {this.state.messages
          ? this.state.messages.map(message => (
            <Alert key={message.id} message={message} />
          ))
          : ""}
      </div>
    );
  }
}
