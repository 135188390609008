import React from 'react';

export default class Image extends React.Component {

  handleButtonClick() {
    this.props.onButtonClick(true);
  }

  render() {
    var src = this.props.src;
    return(
      <div>
        <div>
          <img src={ src } alt='Image' />
        </div>
        <br />
        <div>
          <button onClick={ this.handleButtonClick.bind(this) } className="btn btn-sm btn-primary">
            { I18n.js.crop }
          </button>
        </div>
      </div>
    )
  }
}
