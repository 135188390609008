window.Models = window.Models || {};

Models.Version = {
  clickTableRows: function() {
    $('body').on("click", ".versions-table tbody tr", function(event){
      event.preventDefault();
      var url = $(this).data('url');
      window.location = url;
    });
  }
}

$(document ).ready(function(){
  Models.Version.clickTableRows();
});
$(document).on('page:load', function(){
  Models.Version.clickTableRows();
});

