import React from 'react';
import IssueForm from './IssueForm';
import Loader from './Loader.jsx.erb';

export default class Issue extends React.Component {

  constructor(props)  {
    super(props);
    this.createIssue = this.createIssue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      active: false,
      loadingFlag: false,
      errors: {}
    };
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      active: true
    })
  }

  createIssue(e) {
    e.preventDefault();
    var $form;
    $form = $(e.target);

    this.setState({
      loadingFlag: true
    });

    $.ajax({
      url: this.props.issueUrl,
      method: 'post',
      data: $form.serialize(),
      success: function(issue) {
        this.setState({
          loadingFlag: false,
          active: false,
        });
      }.bind(this),
      error: function(xhr, status, err) {
        grecaptcha.reset();
        this.setState({
          loadingFlag: false,
          errors: xhr.responseJSON
        });
      }.bind(this)
    })
  }

  render() {

    if (this.state.active) {
      var form = <IssueForm
        placeholderText={ this.props.placeholderText }
        submitText={ this.props.submitText }
        issueUrl={ this.issueUrl }
        submitForm={ this.createIssue }
        loadingFlag={ this.state.loadingFlag }
        errors={ this.state.errors } />
    } else {
      var form = <button className='btn btn-sm btn-secondary report_issue' onClick={ this.handleClick } >{this.props.buttonText}</button>
    }

    return (
      <div>
        { form }
        <Loader className="issue-loader" show={this.state.loadingFlag} />
      </div>
    )
  }
}
