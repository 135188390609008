window.Utility = window.Utility || {};

Utility.Form = {
  disableOnSubmit: function(){
    $('.content-container').on('submit', 'form', function(){
      $(this).find('.form-submit, .form-cancel').prop('disabled', true).addClass('disabled');
      $(this).find('.form-loader').show();
    });
  },

  setCountrySelect: function(){
    $('.country-select').select2({
      tags: true,
      tokenSeparators: [';']
    })
  },

  set: function(){
    var _this = this;
    var form = $('form');
    form.off('click');
    form.on('click', '.remove_fields', function(event) {
      event.preventDefault();
      $(this).closest('.nested-form').remove();
    });
    form.on('click', '.add_fields', function(event){
      event.preventDefault();
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).before($(this).data('fields').replace(regexp, time));
      window.autosize = window.autosize ? window.autosize : require('autosize');
      Utility.Form.setCountrySelect();
    });
    form.on('click', '.add_field', function(event){
      event.preventDefault();
      $(this).parents(".panel-body").find(".translations_present").append($(this).data('field'));
      $(this).addClass('d-none');
      window.autosize = window.autosize ? window.autosize : require('autosize');
      Utility.Form.setCountrySelect();
    });
    form.on('click', '.remove_field', function(event) {
      event.preventDefault();
      var $form = $(this).closest('form');
      $(this).parents('.form-group').remove();
      var addLink = $form.find("."+$(this).data('addLinkClass'));
      addLink.removeClass('d-none');
    });
    $('.country-select').select2({
      tags: true,
      tokenSeparators: [',', ' ']
    })

    $('#weight-unit').click(function(e){
      e.preventDefault();
    });
  }
};

$(document).on('turbolinks:load', function(){
  Utility.Form.set();
  Utility.Form.disableOnSubmit();
});
