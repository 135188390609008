export default class Answer {
  onPageLoad(formElement, idLabel, taskFormId, taskName) {
    this.theForm = $(formElement);
    this.setupFields();
    this.setupSectionDropdowns(idLabel);
    this.setupSubmission(taskFormId, taskName);
  };

  setupFields() {
    this.theForm.find('.form-control').focus(function() {
      $(this).removeClass('error-decoration');
    });
  };

  setupSectionDropdowns(idLabel) {
    var _this = this;
    _this.animationsEnabled = false;
    var radios = _this.theForm.find(idLabel);
    $(radios).click(function () {
      var radioValue = $(this).children('input[type="radio"]').val();
      var radio = $(this).children('input[type="radio"]');
      var sectionName = radio.data('controls-section');
      var section = radio.closest('.form-section');
      section.attr('data-answerability', radioValue);
      var controlledSectionSelector = '.form-section-content-data[data-section-name=' + sectionName + ']';
      var $sectionContent = section.find(controlledSectionSelector);
      _this.setVisible($sectionContent, radioValue === 'yes');
      _this.setVisible(_this.theForm.find('#section-issue-' + sectionName), radioValue === 'no');
      $(".alert-danger").fadeOut(2000);
    });
    _this.theForm.find('.form-section-content').attr('data-should-be-shown', true);
    radios.change();
    _this.animationsEnabled = true;
  };

  setupSubmission(taskFormId, taskName) {
    var _this = this;
    _this.theForm.submit(function(evt) {
      var requiredFields = _this.theForm.find('.required:visible');
      var requiredUnit = _this.theForm.find('#unit label');
      var requiredPackage = _this.theForm.find('#package label');
      var requiredIssuesList = _this.theForm.find('#data-issue-list');
      var taskNameTranslationsList = _this.theForm.find('#' + taskFormId);
      var requiredSectionIssue = _this.theForm.find('#section-issue');
      var requiredRadiosIssuesList = document.querySelectorAll(`input[type='radio'][name="task_answer[issues][${taskName}][answerability]"]:checked`).length < 1;
      var taskNameTranslationValuesNumber = Array.from(document.querySelectorAll('#product-' + taskFormId)).map(x => x.value).filter(String).length === 0;
      var incompleteFieldArray = [];
      requiredFields.each(function() {
        var field = $(this);
        var fieldValue = field.val();
        if (fieldValue === undefined || fieldValue === null || (typeof(fieldValue) === 'string' && fieldValue.trim() === '')) {
          incompleteFieldArray.push(field);
        }
        if (requiredUnit.is(':visible') && requiredUnit.hasClass('active') === false) {
          incompleteFieldArray.push(requiredUnit);
        }
        if (requiredPackage.is(':visible') && requiredPackage.hasClass('active') === false) {
          incompleteFieldArray.push(requiredPackage);
        }
        if (requiredRadiosIssuesList && requiredIssuesList.is(':visible')) {
          incompleteFieldArray.push(requiredIssuesList);
        }
        if (requiredSectionIssue.is(':visible') && (fieldValue === undefined || fieldValue === null || (typeof(fieldValue) === 'string' && fieldValue.trim() === ''))) {
          incompleteFieldArray.push(field);
        }
        if (taskNameTranslationValuesNumber && taskNameTranslationsList.is(':visible') && taskName === 'name') {
          incompleteFieldArray.push(taskNameTranslationsList);
        }
      });
      // refactor it
      if (incompleteFieldArray.length > 0 && taskName !== 'nutrient') {
        evt.preventDefault();
        _this.decorateIncompleteFields(incompleteFieldArray);
        return false;
      }
      if (incompleteFieldArray.length === 28 && taskName === 'nutrient') {
        evt.preventDefault();
        _this.decorateIncompleteForm('#nutrients-task-table');
        return false;
      }
      if (incompleteFieldArray.length === 5 && taskName === 'nutrient') {
        evt.preventDefault();
        _this.decorateIncompleteFields(incompleteFieldArray);
        return false;
      }
    });
  };

  setVisible(selector, visible) {
    var animLen = this.animationsEnabled ? 250 : undefined;
    selector.filter('[data-should-be-shown=' + !visible + ']').each(function() {
      if (visible) {
        selector.attr('data-should-be-shown', true);
        selector.stop(true).show(animLen);
      } else {
        selector.attr('data-should-be-shown', false);
        selector.stop(true).hide(animLen);
      }
    });
  };

  decorateIncompleteFields(fieldArray) {
    for (var i in fieldArray) {
      var field = fieldArray[i];
      field.addClass('error-decoration');
      field.effect('shake');
    }
  };

  decorateIncompleteForm(element) {
    $(element).effect('shake');
  };

  getRadioValue(idField) {
    var element = document.getElementById(idField);
    if (element != null) {
      var checkedRadio = element.querySelectorAll('input:checked');
      if (checkedRadio.length > 0) {
        document.getElementById(idField).querySelectorAll('input:checked')[0].value
      }
    }
  };

  setFocus(idField, radioValue) {
    if (radioValue === 'yes') {
      setTimeout(function timeoutFunction() {
        document.getElementById(idField).focus();
      }, 100);
    }
  }
}
