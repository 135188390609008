import axios from 'axios'
import DomHelper from "../utilities/dom_helper";
import Alpine from 'alpinejs';

/**
 * Handles Task form for Mturk
 *  - catches the form submit event
 *  - submit the form with ajax
 *  - on success -> submit the hidden mturk form
 *  - on error -> replace the page with what the server renders
 */
Alpine.TaskAnswerFormMturk = args => {
  return {
    enabled: false,

    init() {
      this.enabled = args.enabled
      this.preview = args.preview

      if (this.enabled) {
        this.$refs.taskForm.addEventListener('submit', this.onAnswerFormSubmit.bind(this))
      }
    },

    onAnswerFormSubmit(event) {
      event.preventDefault()

      if (this.preview) {
        return
      }

      const data = new FormData(event.target)

      axios({
        url: event.target.getAttribute('action'),
        method: 'post',
        data: data,
        headers: {
          'Accept': 'application/json',
        }
      }).then(() => {
        this.submitMturk()
      }).catch(html => {
        this.refresh_frame(html.response.data)
      }).then(() => {
        this.$refs.submitButton.classList.remove('disabled')
        this.$refs.submitButton.disabled = false
      })
    },

    refresh_frame(html) {
      this.$el.parentNode.replaceChild(DomHelper.stringToHTML(html), this.$el)
    },

    submitMturk() {
      this.$refs.mturkForm.submit()
    }
  }
}
