// JS
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import $ from "expose-loader?exposes=$,jQuery!jquery"
import autosize from 'autosize/dist/autosize';
import 'select2';
import 'select2/dist/js/i18n/en.js';
import 'select2/dist/js/i18n/fr.js';
import 'select2/dist/js/i18n/de.js';
import 'select2/dist/js/i18n/it.js';
import 'cropper';
import 'anchor-js';
import 'holderjs';
import 'bootstrap';

import '../stimulusjs/index';
import '../utilities/button_scroll';
import '../utilities/cookies'
import '../utilities/confirmed_click';
import '../utilities/form';
import '../utilities/ios_modal';
import '../utilities/productForm';
import '../utilities/tabs';
import Answer from '../models/community/answer';
import '../models/community/name_task_answer';
import '../models/community/ingredient_task_answer';
import '../models/community/nutrient_task_answer';
import '../models/community/weight_task_answer';
import '../models/community/form_helper';
import '../models/submissions';
import '../models/version';
import '../models/image';
import '../models/sticky';

window.Answer = Answer;
let componentRequireContext = require.context('components', true);
let ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

let utilities = require.context('../utilities/', true, /\.js$/);
utilities.keys().forEach(utilities);

let models = require.context("../models/", true, /\.js$/);
models.keys().forEach(models);

document.addEventListener("turbolinks:load", function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  autosize(document.querySelector('textarea'));
  $('body').addClass('loaded');
});

document.addEventListener('page:before-change', function(){
  $('body').removeClass('loaded');
});

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
import '../alpine_components/base';
