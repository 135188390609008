import React from 'react';
import Loader from './Loader.jsx.erb';

export default class CropBox extends React.Component {

  constructor(props)  {
    super(props);
    this.rotateRight = this.rotateRight.bind(this);
    this.rotateLeft = this.rotateLeft.bind(this);
    this.rotate = this.rotate.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.state = { cropping: false };
  }

  rotateRight(){
    this.rotate(90)
  }

  rotateLeft(){
    this.rotate(-90)
  }

  rotate(angle) {
    $('.croppit').cropper('rotate', angle);
  }

  handleButtonClick() {
    const _this = this;
    this.setState({
      cropping: true,
    });
    var url = this.props.imageUrl;
    var method = 'PATCH';
    var data = {
      image: {
        crop_x: this.state.cropX,
        crop_y: this.state.cropY,
        crop_w: this.state.cropW,
        crop_h: this.state.cropH,
        crop_r: this.state.cropR,
      }
    };
    $.ajax({
      url: url,
      dataType: 'json',
      type: method,
      data: data,
      success: function(data) {
        this.props.onButtonClick(false);
        window.location.reload();
      }.bind(this),
      error: function(xhr, status, err) {
        _this.setState({
          errors: xhr.responseJSON,
          cropping: false
        });
      }
    });

  }

  handleCancelClick() {
    this.props.onButtonClick(false);
  }

  componentDidMount() {
    var _this = this;
    $('.croppit').cropper({
      rotatable: true,
      scalable: true,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      autoCropArea: 0.5,
      restore: false,
      minContainerHeight: 800,
      crop: function(e) {
        const { detail } = e;
        _this.setState({
          cropX: detail.x,
          cropY: detail.y,
          cropW: detail.width,
          cropH: detail.height,
          cropR: detail.rotate,
        })
      }
    });
  }

  render() {
    var src = this.props.src;
    var rotateRightButton =  <label className="btn btn-sm btn-secondary" onClick={ this.rotateRight } title={I18n.js.rotate_clockwise}>
      <img alt='Rotate Right Icon' src='https://d3q151qceqpiv0.cloudfront.net/assets/icons/rotate-right-icon-b8bd6207faf6a1e7a16a449840265010acebc93bc31c84376b30f3f052c815eb.svg' />
                              </label>;
    var rotateLeftButton =  <label className="btn btn-sm btn-secondary" onClick={ this.rotateLeft } title={I18n.js.rotate_counter_clockwise}>
      <img alt='Rotate Left Icon' src='https://d3q151qceqpiv0.cloudfront.net/assets/icons/rotate-left-icon-625672f8badbf1f8a8c92dabc94d813026ce15b282f615c7f5d8107e8edf3e59.svg' />
                            </label>;

    return(
      <div>
        <div className="croppit_container">
          <img className='croppit' src={ src } alt='Croppit' />
        </div>
        <br />
        <div className="img-controls-bg">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            { rotateLeftButton }
            { rotateRightButton }
            <a className="btn btn-sm btn-primary" onClick={ this.handleButtonClick } disabled={ this.state.cropping } >
              <React.Fragment>{I18n.js.crop_it}</React.Fragment>
              <Loader show={this.state.cropping} />
            </a>
            <a className="btn btn-sm btn-secondary" href='#' onClick={ this.handleCancelClick }>
              <React.Fragment>{I18n.js.cancel}</React.Fragment>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
